@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap');
.bg-container-contact100 {
    background-image: url(https://colorlib.com/etc/cf/ContactFrom_v16/images/bg-01.jpg);
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    margin-top: 11rem;
}
.flex-sb-m {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    -ms-align-items: center;
    align-items: center;
}
.bg-container-contact100::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -11;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(41,44,51,.6);
    pointer-events: none;
}
.contact100-header {
    width: 100%;
    height: 80px;
    border-bottom: 1px solid rgba(255, 255, 255, 1);
    padding: 10px 35px;
}
.contact100-header-logo {
    font-family: Montserrat;
    font-size: 14px;
    line-height: 1.7;
    color: #666;
    margin: 0;
    transition: all .4s;
    -webkit-transition: all .4s;
}
.contact100-header-logo img {
    max-height: 23px;
    max-width: 20px;
}
.btn-show-contact100 {
    font-family:"poppins",sans-serif;
    font-weight: 600;
    font-size: 12px;
    color: #846add;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: block;
    min-width: 160px;
    height: 42px;
    background-color: #fff;
    border-radius: 21px;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s;
}
.container-contact100 {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background: rgba(155,72,25,0.5);
    position: relative;
    z-index: 10;
}
.wrap-contact100 {
    width: 560px;
    background:#F4F1E8;
    border-radius: 3px;
    position: relative;
    margin-top: 100px;
}
.btn-hide-contact100 {
    font-size: 30px;
    color: #fff;
    opacity: .6;
    position: absolute;
    right: 0;
    top: -44px;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s;
}
.contact100-form-title {
    font-family: "poppins",sans-serif;
    background-image: url(https://colorlib.com/etc/cf/ContactFrom_v16/images/bg-02.jpg);
    font-size: 30px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;
    width: 100%;
    min-height: 128px;
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-top-left-radius: 2px;
    border-top-right-radius: 3px;
    overflow: hidden;
    padding: 30px 15px;
}
.contact100-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 40px 55px 48px;
}
.validate-input {
    position: relative;
}
.lnr {
    font-family: Linearicons-Free;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.wrap-input100 {
    width: 100%;
    border: 1px solid #e6e6e6;
    border-radius: 2px;
    margin-bottom: 15px;
}
.input.input100 {
    height: 55px;
}
.input100 {
    display: block;
    width: 100%;
    background: 0 0;
    font-family: Montserrat;
    font-size: 15px;
    color: #333;
    line-height: 1.2;
    padding: 0 25px 0 60px;
}
.focus-input100 {
    position: absolute;
    display: block;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    top: -1px;
    left: -1px;
    pointer-events: none;
    border: 1px solid;
    border-radius: 2px;
    border-color: #846add;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s;
    -webkit-transform: scaleX(1.1) scaleY(1.3);
    -moz-transform: scaleX(1.1) scaleY(1.3);
    -ms-transform: scaleX(1.1) scaleY(1.3);
    -o-transform: scaleX(1.1) scaleY(1.3);
    transform: scaleX(1.1) scaleY(1.3);
}
.label-input100 {
    font-size: 25px;
    color:#9B4819;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 60px;
    height: 100%;
    top: 0;
    left: 2px;
}
.m-b-2 {
    margin-bottom: 2px;
}
.lnr {
    font-family: Linearicons-Free;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.lnr-user:before {
    content: "\e82a";
}
.wrap-input100 {
    width: 100%;
    position: relative;
    border: 1px solid #e6e6e6;
    border-radius: 2px;
    margin-bottom: 15px;
}
input.input100 {
    height: 55px;
}
.input100 {
    display: block;
    width: 100%;
    background: 0 0;
    font-family: Montserrat-Regular;
    font-size: 15px;
    color: #333;
    line-height: 1.2;
    padding: 0 25px 0 60px;
}
.contact100-form-title {
    font-family: Montserrat;
    font-size: 30px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase
}

input {
    outline: none;
    border: none;
}
.contact100-form-title::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(67,67,67,.6);
}
textarea.input100 {
    min-height: 162px;
    padding-top: 22px;
    padding-bottom: 15px;
}
.label-input100.rs1 {
    height: 60px;
}
.container-contact100-form-btn {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 17px;
}
.contact100-form-btn {
    font-family: Montserrat-Bold;
    font-size: 12px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px, 20px;
    min-width: 160px;
    height: 42px;
    border-radius: 21px;
    background:#9B4819;
    box-shadow: 0 10px 30px 0 rgba(132,106,221,.5);
    transition: all .4s;
    border: none;
    font-size: 1.7rem;
}