@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;900&display=swap');
.news {
    background-color: #BD8F53;
    height: 28rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.news h2 {
    color: black;
    font-size: 3.5rem;
    margin-bottom: 2rem;
}

.news-text {
    text-align: center;
}

.news input {
    font-size: 1.7rem;
    padding: 1.7rem;
    margin-right: .7rem;
    border: none;
    outline: none;
}

.news button {
    font-size: 1.7rem;
    padding: 1.7rem;
    border: none;
    cursor: pointer;
    color: black;
}
.news button:hover {
    color: white;
    background-color: black;
    transition: all .3s ease-in;
}

@media (max-width:400px) {
    form {
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }

    .news input {
        margin-right: 0;
        text-align: center;
    }
}
.cats-area {
    background:#F4F1E8;
    font-size:2.5rem;
    padding: 10rem 0rem 10rem 0rem
}
.services {
    display: flex;
    flex-direction:row;
    justify-content: space-around;
}
@media(max-width:480px) {
    .services {
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    .cat-icon {
        font-size: 4rem;
    }

}
.cat-icon{
    color: #BD8F53;
    font-size: 6rem;
}
.cat-cap h5 {
    font-weight: 500;
    font-family: "poppins",sans-serif;
    font-size: 2rem;
    color: #000000;
}
.cat-cap p {
    font-family: "poppins",sans-serif;
    font-size: 1.6rem;
    font-weight: 400;
}
@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0,100%,0);
        transform: translate3d(0,100%,0);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
.animation1 {
    visibility: visible;
    animation-duration: 1s;
    animation-delay: 0.2s;
    animation-name: fadeInUp;
}
.animation2 {
    visibility: visible;
    animation-duration: 1s;
    animation-delay: 0.3s;
    animation-name: fadeInUp;
}
.animation3 {
    visibility: visible;
    animation-duration: 1s;
    animation-delay: 0.4s;
    animation-name: fadeInUp;
}
.animation4 {
    visibility: visible;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-name: fadeInUp;
}