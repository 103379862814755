.grid-container {
    display: grid;
    height: 50rem;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: 'one two four' 'one two four-low';
    gap: 1.3rem;
    margin-top: 1.3rem;
}

.home-container {
    padding-top: 12rem;
    background-color: #f4f1e8;
}

.featured {
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

.main-description {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    color: white;
    font-size: 3.8rem;
    font-weight: 600;
}

.featured img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
}

.grid-one {
    grid-area: one;
}

.grid-two {
    grid-area: two;
}

.grid-four {
    grid-area: four;
}

.grid-four-low {
    grid-area: four-low;
}

.lil-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-in;
    visibility: visible;
    animation-duration: 1s;
    animation-delay: 0.1s;
    animation-name: fadeInUp;
}



#img1:hover {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

#img2:hover {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

#img3:hover {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

#img4:hover {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

@media (max-width:750px) {
    .grid-container {
        height: 500px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-template-areas:
            "one two"
            "four four-low";
        grid-gap: 13px;
        gap: 13px;
    }
}

@media (max-width:450px) {
    .main-description {
        bottom: 1rem;
        left: 1rem;
        font-size: 3rem;
    }
}

@media (max-width:400px) {
    .main-description {
        bottom: 1rem;
        left: 0.5rem;
        font-size: 2.5rem;
    }
}