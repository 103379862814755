@import "@fontsource/poppins";
:root{
    --color-212529:#212529;
    --color-25150B:#25150B;
    --color-c2c5db:#C2C5DB;
}

.footer-area.footer-padding{
    color: var(--color-212529);
    font-size: 16px;
    background-color: var(--color-25150B);
    padding: 80px 10px 0px;   
}
.single-footer-caption{
    font-family: "poppins",sans-serif;
    margin: 0 0 30px;
}
.footer-logo {
    color: var(--color-212529);
    font-size: 16px;
    font-family: "Poppins",sans-serif;
    margin-bottom: 35px;
}
.footer-pera {
    color:var(--color-c2c5db);
    font-size: 14px;
    font-family:"Poppins",sans-serif;
    margin: 0px 0px 50px;
    padding: 0px 52px 0px 0px;
    width: 100%;
}
.footer-social{
    color: var(--color-212529);
    font-size: 16px;
    font-family: "Poppins",sans-serif;
}
.footer-social a{
    color: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 14px;
    display: inline-block;
    text-align: center;
    margin-right: 8px;
    border: 1px solid white;
    padding-top: 9px;
}
.footer-social :hover {
    background-color: orangered;
    color: white;
}
.footer-tittle {
    font-size: 16px;
    color: white;
    font-family: "Poppins",sans-serif;
}
.footer-area .footer-tittle h4 {
    color: white;
    font-size: 17px;
    margin-bottom: 40px;
    font-weight: 500;
    font-family: "Poppins",sans-serif;
}
.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
li{
    list-style: none;
}
.footer-area .footer-tittle ul li a {
    color: #C2C5DB;
    font-weight: 300;
    font-size: 14px;
    text-decoration: none;
}
.footer-area .footer-tittle ul li a:hover {
    color: orangered;
    text-decoration: underline transparent;
}
@media (min-width: 1200px) {
.col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;}
}
@media (min-width: 992px)
 {.col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}}
@media (max-width: 768px)
 {.col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}
.row {
    display: flex;
    flex-direction: column;
    text-align: center;
}
}
@media (min-width: 576px)
 {.col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}}
.footer-bottom-area {
    text-align: center;
    color: white;
    padding-bottom: 52px;
}











