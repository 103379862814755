.bg-title-page {
    background-image: url(https://preview.colorlib.com/theme/fashe/images/heading-pages-06.jpg.webp);
    margin-top: 100px;
    width: 100%;
    min-height: 239px;
    padding-left: 15px;
    padding-right: 15px;
    background-repeat: no-repeat;
    background-position: center 0;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}
.l-text2 {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 5rem;
    color: white;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
}
.rows {
    display: flex;
    flex-direction: row;
    margin-top: 10rem;
    gap: 2.5rem;
    background-color: #F4F1E8;
}
.imgsection {
    display: block;
    overflow: hidden;
    transition: all .5s ease-in;
}
.imgsection img {
    width: 430px;
    height: 100%;
    transition: all .5s ease-in;
}
.imgsection :hover {
    scale:1.1;
    transition: all .5s ease-in;
}
.m-text26 {
    font-family: "poppins",sans-serif;
    font-size: 2.5rem;
    color: #333333;
    line-height: 1.2;
    padding-bottom: 16px;
    padding-top: 15px;
    text-align: center;
}
.pb28 {
    padding-bottom: 2.8rem;
    font-family: "poppins",sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.7;
    color: #888888;
    margin: 0px;
}
.b013 {
    border-left: 3px solid #e6e6e6;
    margin-left: 9px;
    padding-left: 2.9rem;
    padding-bottom: 1rem;
}
.pb11{
    padding-bottom: 11px;
    font-family: "poppins",sans-serif;
    font-size: 1.5rem;
    line-height: 1.7;
    color: #888888;
}
.s-text7 {
    font-family: "Montserrat";
    font-size: 13px;
    color: #555555;
    line-height: 1.8;
}
.p-b-30 {
    padding-bottom: 30px;
}
.col-md-4 .col-md-8 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.bg {
    background-color: #F4F1E8;
}
@media (max-width: 768px) {
.rows {
    display: flex;
    flex-direction:column;
}
.imgsection img {
    width: 100%;
}
}
@media (max-width: 480px) {
    .rows {
        display: flex;
        flex-direction:column;
    }
    .imgsection img {
        width: 100%;
    }
    }