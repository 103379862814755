@import url('https://fonts.googleapis.com/css2?family=Moirai+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tilt+Prism&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee+Spice&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap');
.navbar {
    -webkit-box-shadow: 0px 4px 5px -5px rgba(0, 0, 0, 0.23);
    -moz-box-shadow: 0px 4px 5px -5px rgba(0, 0, 0, 0.23);
    box-shadow: 0px 4px 5px -5px rgba(0, 0, 0, 0.23);
    background-color: #9b4819;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}
.brandname{
    font-size: 23px;
    font-family: 'Poppins', sans-serif;
}

.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 12rem;
    transition: height 0.1s linear;
}

.nav-container.cont-sticky {
    height: 8rem !important;
    position: relative;
}

.navbar-fixed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 12rem;
}

.logo-img {
    width: 8.5rem;
    height: auto;
    cursor: pointer;
}

.nav-links {
    font-size: 1.5rem;
    text-transform: uppercase;
    display: flex;
    gap: 2.3rem;
    align-items: center;
}

.nav-links a {
    display: flex;
    color: white;
    text-decoration: none;
    align-items: center;
    font-size:1.8rem;
}
.nav-links a svg {
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 25px;
}

.nav-links a:hover {
    text-decoration: underline;
    color: white;
}

.nav-links i {
    cursor: pointer;
}

/* hamburget menu */

.hamburger-menu {
    display: none;
    gap: 3rem;
}

.hamburger-hamb,
.hamburger-cart {
    font-size: 2.3rem;
    display: none;
    cursor: pointer;
}

.mobile-nav-full {
    background-color: #9b4819;
    z-index: 200;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.open-flex {
    display: flex !important;
}

.closed-flex {
    display: none !important;
}

.mobile-nav-full i {
    font-size: 2.3rem;
    position: absolute;
    right: 25px;
    top: 49px;
    cursor: pointer;
}

.mobile-links {
    font-size: 2.5rem;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    top: 44%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);

}

.mobile-links a {
    color: black;
    text-decoration: none;
}

.mobile-links a:hover {
    text-decoration: underline;
}

/* cart */

.cart-div {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    padding: 3rem 2.5rem 1.5rem 2.5rem;
    background-color: white;
    height: 100vh;
    width: 50rem;
    transition: all 0.4s ease;
}

.page-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000075;
    z-index: 888;
    transition: all 0.3s ease-in;
}

.cart-title-btn {
    font-size: 1.7rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart-full-h2 {
    font-size: 2.1rem !important;
}

.cart-title-btn i {
    font-size: 2.5rem;
    cursor: pointer;
}

.cart-body {
    height: 100vh;
    padding: 0.5rem;
}


.empty-cart {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 16rem;
    margin: 15rem auto;
}

.empty-cart p {
    font-size: 2rem;
    font-weight: 600;
    margin-top: 1rem;
}

.empty-cart button {
    width: 100%;
    height: 4.4rem;
    font-size: 1.7rem;
    margin-top: 3rem;
    cursor: pointer;
    color: #000000;
}

.closed-cart {
    right: -100%;
}

.open-cart {
    right: 0;
}

/* full cart */

.full-cart {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.full-cart-div {
    overflow-y: scroll;
    padding: 5px;
    height: 70%;
    margin-top: 2rem;
    margin-right: -.5rem;
}

.cart-item {
    width: 100%;
    height: 15rem;
    display: grid;
    grid-template-columns: 30fr 50fr 20fr;
    border: 1px solid #717171;
    border-radius: 3px;
    background-color: #f2f2f2;

}

.cart-img {
    width: 100%;
    height: 100%;
    background-color: white;
}

.cart-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
}

.cart-middle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.5rem;
}

.cart-btns {
    display: flex;
}

.cart-btns button {
    font-size: 2rem;
    width: 3rem;
    height: 3rem;
    background-color: black;
    color: white;
    cursor: pointer;
}

.cart-btns p {
    font-size: 2rem;
    outline: 2px solid black;
    outline-offset: -2px;
    width: 3rem;
    text-align: center;
    padding-top: 3.5px;
}

.cart-name {
    font-size: 2.4rem;
}

.cart-right {
    display: flex;
    flex-direction: column;
    text-align: right;
    justify-content: space-between;
    padding: 1.5rem;
    font-size: 2.4rem;
    font-weight: 600;
}

.cart-right i {
    cursor: pointer;
}

.cart-icon {
    position: relative;
    font-size: 3rem;
}

.cart-icon::after {
    content: '';
    top: -18px;
    position: absolute;
    left: 12px;
    font-size: 11px;
    background-color: #B6002C;
    color: white;
    padding: 5px;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    text-align: center;
    display: none;
}

.cart-icon.with-items::after {
    content: attr(data-array-length);
    top: -18px;
    position: absolute;
    left: 12px;
    font-size: 11px;
    background-color: #ffffff;
    color: black;
    padding: 5px;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    text-align: center;
    display: block;
}


/* subtotal */

.subtotal-div {
    display: flex;
    width: 100%;
    height: 12rem;
    border-top: 2px dashed black;
    margin-top: 1.5rem;
    padding: 1rem 0;
    justify-content: space-between;
}

.sub-right {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 2.5rem;
    font-weight: 600;
}

.sub-left a {
    font-size: 2rem;
    color: black;
    border: 2px solid black;
    text-decoration: none;
    background-color: transparent;
    padding: 1rem 3.5rem;
    transition: all 0.2s;
}

.sub-left a:hover {
    background-color: #000000;
    color: white;
}

.sub-left {
    padding-top: 4rem;
}
@media (max-width:1000px){
    .brandnames {
        left: 12%;
    }
}
@media (max-width:800px){
    .brandnames {
        visibility: hidden;
    }
}

@media (max-width:600px) {
    .nav-links {
        display: none;
    }

    .hamburger-menu {
        display: flex;

    }

    .hamburger-cart,
    .hamburger-hamb {
        display: flex;
    }

    .cart-div {
        width: 100%;
    }

    .brandnames{
        visibility: hidden;
    }

}

@media (max-width:420px) {
    .cart-item {
        grid-template-columns: 1fr;
        height: 100%;
        width: 90%;
    }

    .full-cart {
        align-items: center;
    }

    .cart-middle {
        align-items: center;
        gap: 2rem;
    }

    .cart-right {
        text-align: center;
        flex-direction: row;
    }

    .subtotal-div {
        flex-direction: column;
        align-items: center;
    }
    .brandnames{
        visibility: hidden;
    }

    /* .full-cart-div {
        height: 63%;
    } */
}