a {
    text-decoration: none;
    color: black;
}

.proud-container {
    padding: 11rem 0;
    background-color: #f4f1e8;
}

.proud-h2 {
    font-size: 2.6rem;
    font-weight: 600;
    margin-bottom: 4.2rem !important;
    visibility: visible;
    animation-duration: 2s;
    animation-delay: 0.2s;
    animation-name: fadeInUp;
}
@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0,100%,0);
        transform: translate3d(0,100%,0);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
.product {
    outline: 2px solid rgba(0, 0, 0, .205);
    outline-offset: -2px;
    transition: all 0.15s ease-in;
    cursor: pointer;
    visibility: visible;
    animation-duration: 1s;
    animation-delay: 0.2s;
    animation-name: fadeInUp;
}

.product:hover {
    outline: 2px solid rgba(0, 0, 0, .7);
}

.product-header img {
    width: 100%;
    background-color: white;
}

.product-details {
    font-size: 1.7rem;
    padding: 1rem;
}

.item-price {
    font-weight: 600;
    font-size: 2.2rem;
    margin-top: 1.4rem;
}

.products-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 2rem;
}

/* categories */

.catego-header {
    padding-top: 17rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -4rem;
    padding-bottom: 8rem;
}



.catego-header a {
    font-size: 1.6rem;
    text-decoration: none;
    color: black;
}

.filter-btns {
    justify-content: center;
    display: flex;
    gap: 1rem;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
}

.filter-btns button {
    padding: 1rem 1.8rem;
    color: black;
    cursor: pointer;
    background-color: transparent;
    border: 2px solid rgba(0, 0, 0, 0.3);
    border-radius: 9px;
    transition: all 0.2s;
    margin: 5px;
}

.filter-btns button:hover {
    border:none;
    background-color: #9b4819;
    scale: 1.2;
    color: white;
    font-weight: 500;
}

.title-home {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 6rem;
}

.title-home h3 {
    font-size: 2.7rem;
    text-transform: uppercase;
    text-align: center;
    margin: 0 auto;
}

.title-home a {
    font-size: 1.6rem;
    text-decoration: none;
    color: black;
    text-align: left;
}



/* queries */

@media (max-width:750px) {
    .products-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 2rem;
    }
}

@media (max-width:600px) {
    .products-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
}

@media (max-width:430px) {
    .products-grid {
        grid-template-columns: 1fr;
    }
}