.register-bg {
  height: 120vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(https://img.freepik.com/free-photo/black-friday-elements-assortment_23-2149074076.jpg);
}
.register-form-area {
  position: relative;
  top: 50%;
  left: 50%;
  width: 30%;
  transform: translate(-50%, -50%);
}
.register-form-area .register-form {
  background: #fff;
  padding: 55px 60px 50px 50px;
  box-shadow: 0px 10px 30px 0px rgba(13, 12, 13, 0.2);
}
.register-form-area .register-form .register-heading {
  text-align: center;
}
.register-form-area .register-form .register-heading span {
  color: #140c40;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 16px;
  display: block;
  text-transform: capitalize;
}
.register-form-area .register-form .register-heading p {
  font-family: "Poppins", sans-serif;
  color: #646d77;
  font-size: 14px;
  line-height: 30px;
  margin-bottom: 15px;
  font-weight: normal;
  line-height: 1.4;
}
.register-form-area .register-form .input-box {
  padding-top: 35px;
  padding-bottom: 60px;
}
.register-form-area .register-form .input-box .single-input-fields label {
  display: block;
  font-size: 17px;
  margin-bottom: 6px;
  color: #140c40;
  text-transform: capitalize;
  font-weight: 500;
  text-align: left;
}
.register-form-area .register-form .input-box .single-input-fields input {
  border: 1px solid #c9c9c9;
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
  padding: 0 25px;
  color: #000;
}
.register-form-area .register-form .register-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.register-form-area .register-form .register-footer p {
  margin: 0;
  font-family: "Poppins", sans-serif;
  color: #646d77;
  font-size: 14px;
  line-height: 30px;
  margin-bottom: 15px;
  font-weight: normal;
  line-height: 1.4;
}
.submit-btn3 {
  background: #fd8f5f;
  height: 60px;
  padding: 10px 43px;
  border: 0;
  color: #fff;
  text-transform: capitalize;
  cursor: pointer;
  font-size: 16px;
  border-radius: 0px;
}
.register-form-area .register-form .register-footer p a {
    color: #f37c49;
    text-decoration: none;
}
@media (max-width:1027px) {
    .register-form-area {
        width: 100%;
        height: 50%;
    }
}
@media (max-width:540px) {
    .register-form-area {
        margin-top: 25px;
        width: 100%;
    }
    .submit-btn3  {
        width: 30px;
        font-size: 15px;
        padding:0;
        width: 200px;
    }
    .p a{
        font-size: small;
    }
    .Login-footer .p {
        font-size:small;
    }
    .fruit1 f-right {
        font-size: 2px;
    }
    .register-form-area .register-form .input-box .single-input-fields label {
        font-size: 15px;
        margin-right: 3px;
    }
}
@media (max-width:380px) {
    .register-bg {
        height: 100%;
    }
    .submit-btn3  {
        width: 30px;
        font-size: 15px;
        padding:0;
        width: 200px;
    }
    .p a{
        font-size: small;
    }
    .register-footer .p {
        font-size:small;
    }
    .fruit1 f-right {
        font-size: 2px;
    }
    .register-form-area .register-form .input-box .single-input-fields label {
        font-size: 15px;
        margin-right: 3px;
    }
}
